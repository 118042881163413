import '../App.css';
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {getChristmasPackageByConfiguration} from "../services/packsService";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import illustration1 from "../assets/christmas/1.jpg";
import illustration4 from "../assets/christmas/4.jpg";
import illustration3 from "../assets/christmas/3.jpg";
import illustration2 from "../assets/christmas/2.jpg";
import bigIllustration1 from "../assets/christmas/big/1.jpg";
import bigIllustration4 from "../assets/christmas/big/4.jpg";
import bigIllustration3 from "../assets/christmas/big/3.jpg";
import bigIllustration2 from "../assets/christmas/big/2.jpg";
import {Carousel} from "react-responsive-carousel";
import {AutoAwesome, CheckCircle, Park, Redeem} from "@mui/icons-material";
import {Box, Grid, Typography} from "@mui/material";
import {addToUserBag} from "../services/bagService";
import {Link, useNavigate} from "react-router-dom";
import {isAuthenticated} from "../services/authStorageService";
import Divider from "@mui/material/Divider";
import {IBookThumbnail} from "../services/model/Books";

function ChristmasPacks() {

    const [validated, setValidated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [price, setPrice] = useState('123,00 lei');
    const [catalogPrice, setCatalogPrice] = useState('0 lei');
    const [initBooks, setInitBooks] = useState<IBookThumbnail[]>([]);
    const [startAge, setStartAge] = useState(2);
    const [init, setInit] = useState(0);
    const [numberOfBooks, setNumberOfBooks] = useState(3);
    const navigate = useNavigate();

    useEffect(()=> {
        if (init === 0) {
            window.scrollTo(0, 0);
            setInit(1);
        }
        setServerError(false);
        updatePrice();
    },[startAge, numberOfBooks])

    const handleStartAgeChange = (e : ChangeEvent<HTMLSelectElement>) => {
        setStartAge(Number(e.target.value));
    };

    const handleNumberOfBooks = (e : ChangeEvent<HTMLSelectElement>) => {
        setNumberOfBooks(Number(e.target.value));
    };

    const updatePrice = () => {
        getChristmasPackageByConfiguration(startAge, numberOfBooks).then((response) => {
            setPrice(response.data.price + ' lei');

            // let catalogPrice = response.data.initialBooks.reduce((sum, book) => sum + book.catalogPrice, 0);
            // catalogPrice = catalogPrice + 20.0;
            // catalogPrice = parseFloat(catalogPrice.toFixed(2));
            // setCatalogPrice(catalogPrice + ' lei');
            let number = response.data.price + 20/100*response.data.price;
            number = parseFloat(number.toFixed(0));
            setCatalogPrice(number + ' lei');
            setInitBooks(response.data.initialBooks)
        }).catch(error => {
            console.error('Get christmas package by configuration Error :', error);
            setServerError(true);
        });
    };

    const handleSubmit = (event: FormEvent) => {
        if (!isAuthenticated()) {
            setShowModal(true);
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        const form = event.currentTarget;
        // @ts-ignore
        if (form.checkValidity() === false) {
        } else {
            getChristmasPackageByConfiguration(startAge, numberOfBooks).then((response) => {
                setServerError(false);
                const bookPackageConfiguration = response.data;

                addToUserBag({itemId: bookPackageConfiguration.id, itemType: 'CHRISTMAS_PACKAGE'}).then(() => {
                    navigate("/my-bag");
                }).catch(error => {
                    console.error('Get christmas package by configuration Error :', error);
                    // Display user-friendly error message
                    setServerError(true);
                });
            }).catch(error => {
                console.error('Get christmas package by configuration Error :', error);
                // Display user-friendly error message
                setServerError(true);
            });
        }

        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    function handleClose() {
        setShowModal(false);
    }

    function getMdLg() {
        return numberOfBooks === 3 ? 4 : 3;
    }

    return (
        <>
            <div className="App">

                <div id="carousel-small" className="banner-small div-with-background ">
                    <Carousel showArrows={false} autoPlay={true} animationHandler={"slide"} axis={"horizontal"}
                              infiniteLoop={true} showThumbs={false} showStatus={false}>
                        <div>
                            <div className="div-with-background banner-image">
                                <div>
                                    <img src={illustration1} alt="illustration1"/>
                                </div>
                                <div className="top-section-xmas">
                                    <div>
                                        <h2><strong>Cadouri care aduc bucurie!</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="div-with-background banner-image">
                                <div>
                                    <img src={illustration2} alt="illustration4"/>
                                </div>
                                <div className="top-section-xmas">
                                    <div>
                                        <h2><strong>Cadouri care ne îmbogățesc!</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="div-with-background banner-image">
                                <div>
                                    <img src={illustration3} alt="illustration3"/>
                                </div>
                                <div className="top-section-xmas">
                                    <div>
                                        <h2><strong>Cadouri care vor crea amintiri!</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="div-with-background banner-image">
                                <div>
                                    <img src={illustration4} alt="illustration2"/>
                                </div>
                                <div className="top-section-xmas">
                                    <div>
                                        <h2><strong>Cadouri care ne îmbogățesc!</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Carousel>
                </div>
                <div id="carousel-big" className="banner-big">
                    <Carousel showArrows={false} autoPlay={true} animationHandler={"slide"} axis={"horizontal"}
                              infiniteLoop={true} showThumbs={false} showStatus={false}>
                        <div>
                            <div className="div-with-background banner-image">
                                <div>
                                    <img src={bigIllustration1} alt="illustration1"/>
                                </div>
                                <div className="top-section">
                                    <div>
                                        <h2><strong>Cadouri care aduc bucurie!</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="div-with-background banner-image">
                                <div>
                                    <img src={bigIllustration2} alt="illustration4"/>
                                </div>
                                <div className="top-section">
                                    <div>
                                        <h2><strong>Cadouri care ne îmbogățesc!</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="div-with-background banner-image">
                                <div>
                                    <img src={bigIllustration3} alt="illustration3"/>
                                </div>
                                <div className="top-section">
                                    <div>
                                        <h2><strong>Cadouri care vor crea amintiri!</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="div-with-background banner-image">
                                <div>
                                    <img src={bigIllustration4} alt="illustration2"/>
                                </div>
                                <div className="top-section">
                                    <div>
                                        <h2><strong>Cadouri care ne îmbogățesc!</strong>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Carousel>
                </div>

                <div id="wave" className="parent align-left top-section div-with-background">
                    <div className="small-container ">
                        <div className="banner-big top-section">
                            <div>
                                <h2><strong>Cadouri care aduc bucurie și ne îmbogățesc!</strong>
                                </h2>
                            </div>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h5><AutoAwesome sx={{color: "var(--button-hover-background)"}}/> În perioada
                                    sărbătorilor de iarnă cu toții ne dorim cadoul perfect pentru copilul nostru.</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <h5><Redeem sx={{color: "var(--button-hover-background)"}}/> Noi am selectat cele mai
                                    frumoase și valoroase cărți și le-am pus în pachete, în funcție de vârsta
                                    copilului. </h5>
                            </Grid>
                            <Grid item xs={12}>
                                <h5><Park sx={{color: "var(--button-hover-background)"}}/> Tu nu trebuie decât să le pui
                                    în ghetuțe sau sub brad.</h5>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <div className="parent top-section div-with-background">
                    <div className="top-section">
                        <h2><strong>Ce conține pachetul de Crăciun Raise A Reader?</strong></h2>
                    </div>
                </div>
                <div id="wave" className="parent align-left top-section div-with-background">
                    <div className="small-container ">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h5><CheckCircle sx={{color: "var(--cefacem-dark-color)"}}/> 3 sau 4 cărți de Crăciun și
                                    iarnă alese în funcție de vârsta copilului.</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <h5><CheckCircle sx={{color: "var(--cefacem-dark-color)"}}/> Resurse educaționale: acces
                                    la întrebări menite să susțină citirea interactivă și să pornească dialoguri
                                    interesante.

                                    {/*De la stimularea creativității la consolidarea lecțiilor din cărți, resursele noastre sunt */}
                                    {/*modalități puternice de a te conecta cu copilul tău, susținând în același timp dezvoltarea acestuia.*/}
                                </h5>
                            </Grid>
                            <Grid item xs={12}>
                                <h5><CheckCircle sx={{color: "var(--cefacem-dark-color)"}}/> Resurse educaționale: acces
                                    la activități inspirate din cărți prin intermediul cărora veți aduce povestea la
                                    viață.</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <h5><CheckCircle sx={{color: "var(--cefacem-dark-color)"}}/> Ghidul nostru pentru
                                    părinți cititori -
                                    sfaturi și idei pentru ca tu și copilul tău să vă bucurați cât mai mult de momentele
                                    de lectură.
                                </h5>
                            </Grid>

                        </Grid>
                    </div>
                </div>

                <div className="parent top-section div-with-background" style={{flexDirection:"column"}}>
                    <div className="top-section">
                        <div>
                            <h2><strong>Configurează-ți pachetul în funcție de nevoile tale</strong>
                            </h2>
                        </div>
                    </div>
                    <div className="border align-left"
                         style={{marginTop: '10px', color: "black", fontSize: "initial", minWidth: "360px"}}>
                        {serverError ?
                            <div className="from-error">Eroare: ne pare rău, pachetele din această categorie sunt SOLD
                                OUT.</div> : <></>}
                        <Form id="christmas-form" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                                <Form.Label>
                                    Vârsta copilului
                                </Form.Label>

                                <Form.Select
                                    // as="select"
                                    name="startAge"
                                    onChange={handleStartAgeChange}
                                    required
                                >
                                    <option value="0">0-2 ani</option>
                                    <option value="2" selected>2-3 ani</option>
                                    <option value="3">3-4 ani</option>
                                    <option value="4">4-5 ani</option>
                                    <option value="5">5-6 ani</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Te rugăm să selectezi vârsta copilului.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                                <Form.Label>
                                    Numărul de cărți din pachet
                                </Form.Label>
                                <Form.Select
                                    as="select"
                                    name="numberOfBooks"
                                    onChange={handleNumberOfBooks}
                                    required
                                >
                                    <option value="3" selected>3</option>
                                    <option value="4">4</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Te rugăm să selectezi numărul de cărți din pachet.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div>
                                <Grid container spacing={2}>
                                    {initBooks !== undefined && initBooks
                                        .filter((book) => book.id !== null)
                                        .map((book, index) => {
                                                return (
                                                    <Grid item xs={6} sm={6} md={getMdLg()} lg={getMdLg()} key={book.id}>
                                                        <Box>
                                                            <div className={"image_wrapper"}>
                                                                <Link to={`/books/details/` + book.slug}>
                                                                    {book.photoUrl ? (
                                                                        <Box key={book.photoUrl} sx={{
                                                                            marginBottom: 2,
                                                                            filter: 'drop-shadow(rgba(205, 180, 219, 0.5) 20px 30px 10px)'
                                                                        }}>
                                                                            <img className='image_wrapper bookInPlan'
                                                                                 src={book.photoUrl}
                                                                                 alt={book.name} style={{
                                                                                maxHeight: '190px',
                                                                                maxWidth: '190px',
                                                                                objectFit: 'cover'
                                                                            }}/>
                                                                        </Box>

                                                                    ) : (
                                                                        <Typography variant="body2">No photos
                                                                            available</Typography>
                                                                    )}
                                                                </Link>
                                                                <div className="fauna-one-regular">
                                                                    <Typography variant="h6"
                                                                                gutterBottom>{book.name}</Typography>
                                                                </div>
                                                                <div className="overlay overlay_2">
                                                                    <h5><strong>Vezi detalii</strong></h5>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                )
                                            }
                                        )}
                                </Grid>
                            </div>


                            <Row className="g-2">


                                <Col style={{
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    color: "var(--cefacem-dark-color)"
                                }}>
                                    <h3 style={{
                                        fontWeight: '400',
                                        marginRight: '.3em',
                                        opacity: '.6',
                                        textDecoration: 'line-through'
                                    }}>
                                        {catalogPrice}
                                    </h3>

                                    <h3>{price}</h3>
                                </Col>
                            </Row>


                            <Form.Group className="mb-3">
                                <Row className="g-2">
                                    <Col style={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        flexDirection: "row"
                                    }}>
                                        <Button type="submit" className="button button-contact">
                                            Selectează pachetul
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Divider variant="fullWidth" sx={{bgcolor: 'purple'}}/>
                            <h5 className="top-section">
                                Vrei să îți înlocuim una din cărți?
                            </h5>
                            <h6 style={{textAlign: 'justify'}}>
                                Dacă ai deja o carte, o poți schimba gratuit cu o altă carte din selecția noastră.
                                Tot ce trebuie să faci este să ne lași un mesaj în formularul de comandă.
                            </h6>

                            <Button className="button-contact">
                                <Link to={"/books/campaign/christmas"}  style={{color: "white"}}>
                                    Vezi aici selecția noastră de cărți
                                </Link>
                            </Button>
                        </Form>


                        <Modal
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={showModal} onHide={handleClose}
                        >
                            <Modal.Body className="modal-body-scrollable">
                                <div style={{paddingTop: '15px', paddingRight: '15px', paddingLeft: '15px'}}>
                                    Pe lângă cărțile fizice, pachetele de Crăciun îți oferă acces și la resurse
                                    educaționale digitale.
                                </div>
                                <div style={{paddingTop: '15px', paddingRight: '15px', paddingLeft: '15px'}}>
                                    Te rugăm să te autentifici sau să îți creezi un cont înainte de a comanda.
                                </div>
                                <div style={{padding: '15px'}}>
                                    Îți mulțumim!
                                </div>
                                <Link to="/login" style={{paddingLeft: '15px'}}>
                                    <Button className="button button-contact">
                                        Intră în cont
                                    </Button>
                                </Link>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className="gradient-wave-bg"></div>

                <div className="gradient-wave-bg-orange flip "
                     style={{marginTop: '-25px!important', transform: 'rotate(180deg)'}}></div>
                <div className="parent top-section orange-background">
                    <div className="top-section">
                        <h2><strong>Ce sunt resursele educaționale?</strong></h2>
                    </div>
                </div>
                <div className="parent align-left top-section orange-background " style={{marginTop: '-45px'}}>
                    <div className="align-left small-container">
                        <br/>
                        <h5 style={{paddingBottom: "10px", textAlign: 'justify'}}>
                            Resursele educaționale (<strong>sugestii
                            de dialog și idei de activități și joacă</strong>) la care vei primi acces după confirmarea
                            comenzii sunt menite să
                            susțină citirea interactivă, fiind o modalitate grozavă de a îmbunătăți limbajul,
                            înțelegerea și
                            abilitățile de gândire critică ale copilului tău.
                        </h5>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h5 style={{textAlign: 'justify'}}><CheckCircle sx={{color: "var(--cefacem-dark-color)"}}/>
                                    <strong> Sugestiile de dialog</strong> sunt gândite pentru a face din momentul
                                    lecturii o
                                    experiență captivantă și interactivă pentru copil. Stimulează empatia, imaginația și
                                    dragostea pentru povești.
                                </h5>
                            </Grid>
                            <Grid item xs={12}>
                                <h5 style={{textAlign: 'justify'}}><CheckCircle sx={{color: "var(--cefacem-dark-color)", textAlign: 'justify'}}/>
                                    <strong> Activitățile</strong> sunt concepute pentru a aduce povestea la viață.
                                    Combinând
                                    lectura cu jocul crativ, copilul își va îmbunătăți înțelegera, îi va fi stimulată
                                    creativitatea, abilitățile motorii și creșterea emoțională.
                                </h5>
                            </Grid>
                        </Grid>
                    </div>

                    <section className="span-minor-text ">
                        <Link to="/books/details/mamaaa">
                            <div className="button button-contact button-contact-orange"
                                 style={{marginLeft: '10px', width: "fit-content"}}>
                                <span>Vezi aici un exemplu</span>
                            </div>
                        </Link>
                    </section>
                </div>


                <div className="parent top-section orange-background">
                    <div className="top-section">
                        <h2><strong>Ce conține ghidul?</strong></h2>
                    </div>
                </div>
                <div className="parent align-left top-section orange-background " style={{marginTop: '-25px'}}>
                    <div className="align-left small-container">
                        <h5 style={{textAlign: 'justify'}}>Parcurgănd <strong>ghidul nostru pentru părinți cititori</strong> vei afla care este
                            momentrul potrivit să îi citești copilului tău, cum poți să îi crești
                            interesul pentru lectură și multe alte informații validate de consultanți în educația
                            timpurie.
                        </h5>
                    </div>
                    <div className="column-50" style={{alignItems: "center"}}>
                    <Box
                        key="https://raise-a-reader-photo-public.s3.eu-west-1.amazonaws.com/packages/ghidV2.png"
                        sx={{
                            marginBottom: 2,
                            filter: 'drop-shadow(rgba(205, 180, 219, 0.5) 20px 30px 10px)'
                        }}>
                        <img className='bookInPlan'
                             src="https://raise-a-reader-photo-public.s3.eu-west-1.amazonaws.com/packages/ghidV2.png"
                             alt="Ghidul" style={{
                            maxHeight: '400px',
                            maxWidth: '300px',
                            minHeight: '400px',
                            minWidth: '300px',
                            objectFit: 'cover'
                        }}/>
                    </Box>
                </div>
                </div>
                <div className="parent align-left top-section orange-background " style={{marginTop: '-25px'}}>
                    <Button className="button button-contact " style={{marginTop: '20px'}}
                            onClick={() => document.getElementById('configure-package')?.scrollIntoView({behavior: 'smooth'})}>
                        Alege-ți pachetul acum
                    </Button>
                </div>
                <div className="gradient-wave-bg-orange " style={{marginTop: '-10px'}}></div>
            </div>
            <div>
            </div>

        </>
    );
}

export default ChristmasPacks;
