import {http} from "../core/services/http";
import {ColorDto, LibraryDto} from "./model/ILibraryModel";
import {AddItemRequest, AddNoteRequest, AddVoucherRequest, UserBag} from "./model/IBagModel";

export const getUserBag = () =>
    http.get<UserBag>('/commercial/user-bag')

export const addToUserBag = (request: AddItemRequest) =>
    http.post<UserBag>('/commercial/user-bag/item', request)

export const deleteItem = (id:string) =>
    http.delete<UserBag>(`/commercial/user-bag/item/${id}`)

export const setVoucherOnBag = (request: AddVoucherRequest) =>
    http.post<UserBag>('/commercial/user-bag/voucher/add', request)

export const setNoteOnBag = (request: AddNoteRequest) =>
    http.post<UserBag>('/commercial/user-bag/note', request)


export const removeVoucherFromBag = () =>
    http.delete<UserBag>('/commercial/user-bag/voucher')