import {http} from "../core/services/http";
import {ColorDto, LibraryDto} from "./model/ILibraryModel";

export const getColors = () =>
    http.get<ColorDto[]>('/books/colors')

export const getUserLibrary = () =>
    http.get<LibraryDto>('/books/library')

export const downloadResourceFromAPI = (id: string) =>
    http.get<Blob>(`/books/library/resources/${id}`, { responseType: 'blob' });
        // .then(response => response.data.arrayBuffer())
        // .then(buffer => new Uint8Array(buffer));
