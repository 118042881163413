import * as React from 'react';
import {Button} from 'react-bootstrap'
import {logout} from "../../services/userService";
import { LogoutRequest} from "../../services/model/LoginRequest";
import {Link} from "react-router-dom";
import {
    getRefreshToken,
    isAuthenticated, removeRefreshToken, removeToken,
    setAuthenticated
} from "../../services/authStorageService";
import {ShoppingCart} from "@mui/icons-material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
};


const LoginModal = () => {
    const handleLogout = async () => {
        let logoutRequest : LogoutRequest = {
            refreshToken : getRefreshToken()
        };
        const response = await logout(logoutRequest);

        removeToken();
        removeRefreshToken();
        setAuthenticated(false)
        // Redirect or perform other actions upon successful login
        window.location.reload();

    }

    const handleResetPassword = async (e : Event) => {
//todo
    }

    return (
        <div>
            {
                !isAuthenticated() ?
                    <Link to="/login">
                        <Button className="button-just-text">
                            Intră în cont
                        </Button>
                    </Link>
                :

                    <div>
                        <Link to="/my-library">
                            <Button className="button-just-text">
                                Cărțile mele
                            </Button>
                        </Link>
                        <Link to="/my-account">
                            <Button className="button-just-text">
                                Contul meu
                            </Button>
                        </Link>

                        <Link to="/my-bag">
                            <ShoppingCart sx={{color: "var(--cefacem-dark-color)"}}/>
                        </Link>
                    </div>
            }
        </div>
    );
}

export default LoginModal;