import {Col} from "react-bootstrap";
import {Typography} from "@mui/material";
import React from "react";

type Props = {
    left: string,
    right: string,
    type: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "body2" | "overline" | undefined
}

function LeftRightEntry(props: Props) {
    return(
        <>
            <Col xs style={{textAlign:"left"}}>
                <Typography variant={props.type}>{props.left}</Typography>
            </Col>
            <Col xs style={{textAlign:"right"}}>
                <Typography variant="h6">{props.right}</Typography>
            </Col>
        </>
    );
}
export default LeftRightEntry;