import React, {useEffect, useState} from "react";
import {UserProfile} from "../../services/model/UserProfile";
import {Box, Typography} from "@mui/material";
import {BillingAddress, DeliveryAddress} from "../../services/model/Payments";
import {Button} from "react-bootstrap";

type Props = {
    deliveryAddress: DeliveryAddress,
    setExpand?: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

function DeliveryAddressStaticShower(props: Props) {

    return (
        <>
            <div className="border align-left"
                 style={{marginTop: '10px', color: "black", fontSize: "initial", minWidth: "360px"}}>
            <Typography variant="h5">Detalii de livrare</Typography>
                <div className="parent">
                    <div className="column-50-no-equal" style={{alignItems:"flex-start"}}>
                        {props.deliveryAddress && props.deliveryAddress.firstName !== '' ?
                            <>
                                <Typography>{props.deliveryAddress.firstName} {props.deliveryAddress.lastName} </Typography>
                                <Typography>{props.deliveryAddress.email}, {props.deliveryAddress.phone} </Typography>
                                <Typography>{props.deliveryAddress.address}, {props.deliveryAddress.city}, {props.deliveryAddress.county}, {props.deliveryAddress.country} </Typography>
                                <Typography>{props.deliveryAddress.postCode} </Typography>
                            </>
                            :
                            <></>
                        }

                    </div>
                    <div className="column-50-no-equal">
                        <Button className="button-just-text3" onClick={() => {props.setExpand && props.setExpand(true)}}>
                            {props.deliveryAddress && props.deliveryAddress.firstName !== '' ?
                                    <Typography>Editează</Typography>
                                :
                                    <Typography>Adaugă</Typography>
                            }
                        </Button>
                    </div>
                </div>
                </div>
        </>
);
}

export default DeliveryAddressStaticShower;
