import React, {useCallback, useEffect, useState} from "react";
import {getBillingAddress} from "../../services/userService";
import {UserProfile} from "../../services/model/UserProfile";
import {BillingAddress, PaymentMethod, UserOrder} from "../../services/model/Payments";
import BillingAddressHandler from "./BillingAddressHandler";
import BillingAddressStaticShower from "./BillingAddressStaticShower";
import UserCardsShower from "./UserCardsShower";
import {getOrders, getPaymentMethods} from "../../services/paymentsService";

type Props = {
    parentUpdate: boolean
    affiliate: boolean
}

function OrderDetailsList(props : Props)  {

    const [orders, setOrders] = useState<UserOrder[]>([]);
    const [update, setUpdate] = useState(true);
    const getData = useCallback(async () => {
        try {
            const orderResponse = await getOrders(props.affiliate);
            setOrders(orderResponse.data);

        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        if (update || props.parentUpdate) {
            getData();
        }
        setUpdate(false);
    }, [update, props.parentUpdate])


    function getDisplayValue(status: string) {
        if (status === 'PAID') {
            return "Plătită";
        }
        if (status === 'PAYMENT_PENDING') {
            return "În procesare";
        }

        if (status === 'FAILED') {
            return "Respinsă";
        }

        if (status === 'CANCELED' || status === 'REFUNDED') {
            return "Anulată";
        }

    }

    return (
        <>
            <div className="info-topic2" >
                <div style={{padding:"20px"}}>
                    {   orders.map((order, index) => {
                        return (
                            <div className="border" style={{marginBottom:"10px"}}>
                                    <h5><b>Comanda: {order.orderNumber}</b></h5>
                                    <h6>Data: {new Date(order.createdAt).toLocaleDateString('ro', {
                                        day: '2-digit',
                                        month: 'long',
                                        year: '2-digit'
                                    }).replace(',', '/')}</h6>
                                    <h6>Suma: {order.amountPaid} RON</h6>
                                    <h6>Stare: {getDisplayValue(order.status)} </h6>
                            </div>
                        );
                        }
                    )}
                </div>
            </div>


        </>
    );
}

export default OrderDetailsList;
