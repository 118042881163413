import React, {ChangeEvent, FormEvent, useState} from 'react';
import '../App.css';
import Form from "react-bootstrap/Form";
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";
import {register} from "../services/userService";
import {RegisterRequest} from "../services/model/RegisterRequest";
import {Button, Col, Row} from "react-bootstrap";

function Register() {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [registerRequest, setRegisterRequest] = useState<RegisterRequest>({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: ''
    });

    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        setRegisterRequest({
            ...registerRequest,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = (event: FormEvent) => {
        const form = event.currentTarget;
        // @ts-ignore
        if (form.checkValidity() === false) {
        } else {
            register(registerRequest).then((response) => {
                setServerError(false);
                // Redirect or perform other actions upon successful register
                navigate("/login");
                window.location.reload();
            }).catch(error => {
                console.error('Register Error :', error);
                // Display user-friendly error message
                setServerError(true);
            });
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };
  return (
      <>

          <div className="App">
              <div id="wave" className="parent top-section div-with-background">
                  <div className="small-container align-left-mobile-only">
                      <h1>
                          Cont nou
                      </h1>
                      <h5>
                          Înregistrează-te pentru a debloca instantaneu <span
                          className="low-highlight-purple">mai multe
                          cărți</span>!
                      </h5>
                  </div>
              </div>
              <div className="parent top-section div-with-background">
                  <div className="border align-left"
                       style={{marginTop: '10px', color: "black", fontSize: "initial", minWidth: "360px"}}>
                      {serverError ? <div className="from-error">Eroare: ceva nu a funcționat. Asigură-te că nu ai un alt cont cu același email sau număr de telefon.</div> : <></>}
                  <Form id="login-form" noValidate validated={validated} onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                          <Row className="g-2">
                              <Col md>
                                  <Form.Label>
                                      Prenume *
                                  </Form.Label>
                                  <Form.Control
                                      type="input"
                                      name="firstName"
                                      placeholder="Prenume"
                                      onChange={handleChange}
                                      required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      Te rugăm să introduci prenumele.
                                  </Form.Control.Feedback>
                              </Col>
                              <Col md>
                                  <Form.Label>
                                      Nume *
                                  </Form.Label>
                                  <Form.Control
                                      type="input"
                                      name="lastName"
                                      placeholder="Nume"
                                      onChange={handleChange}
                                      required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      Te rugăm să introduci numele.
                                  </Form.Control.Feedback>
                              </Col>
                          </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                          <Form.Label>
                              Telefon *
                          </Form.Label>
                          <Form.Control
                              type="phone"
                              required
                              name="phone"
                              placeholder="Telefon"
                              onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                              Te rugăm să introduci un număr de telefon valid.
                          </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3"  style={{paddingTop: '5px'}}>
                          <Form.Label>
                              Email *
                          </Form.Label>
                          <Form.Control
                              type="email"
                              required
                              name="email"
                              placeholder="Email"
                              onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                              Te rugăm să introduci un email valid.
                          </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" style={{paddingTop: '5px'}}>

                          <Form.Label className="d-flex flex-column mb-2">
                              Parolă *
                          </Form.Label>
                          <Form.Control
                              type="password"
                              required
                              name="password"
                              placeholder="Parolă"
                              onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                              Te rugăm să introduci o parolă.
                          </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" style={{textAlign: 'center'}}>
                          <Button type="submit" className="button button-contact">
                              Înregistrează-te
                          </Button>
                          <Typography id="modal-modal-title" variant="h6" component="h2"
                                      className="fauna-one-regular header span-minor-text"
                                      style={{textAlign: 'center'}}>
                              Ai deja cont?<br/> <Link to="/login">Loghează-te aici</Link>
                          </Typography>
                      </Form.Group>

                  </Form>
                  </div>
              </div>
          </div>

          <div className="gradient-wave-bg"></div>
      </>
  )
      ;
}

export default Register;
