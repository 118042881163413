import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../App.css';
import {Box, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import {Link, useParams} from "react-router-dom";
import {IBookThumbnail} from "../services/model/Books";
import {getFilteredBooks, getPublicBooks} from "../services/booksService";
import RegisterSection from "../core/components/RegisterSection";
import {Park} from "@mui/icons-material";
import {Button} from 'react-bootstrap'

function Books() {
    const {filter, slug} = useParams();
    const observer = useRef<IntersectionObserver>();

    const [books, setBooks] = useState<IBookThumbnail[]>([]);
    const [totalNumber, setTotalNumber] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChristmasCampaign, setIsChristmasCampaign] = useState<boolean>(window.location.href.includes('/campaign/christmas'));

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    let itemsPerRow = isSm || isXs ? 2 : 4;

    const lastElementRef = useCallback(
        (node: HTMLDivElement) => {
            console.log("lastElementRef", node);
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && page < totalPages ) {
                    console.log("entries", entries[0]);
                        setPage(page + 1);
                        console.log("pageInRef: ",page);

                        getData(page, size)
                } else {
                    console.log("not visible", entries[0]);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading]
    );

    const getData = useCallback(async (page:number, size:number) => {
        setIsLoading(true);
        try {
            var pageBooks;
            if (filter === undefined || slug === undefined) {
                pageBooks = await getPublicBooks(page, size);
            } else {
                pageBooks = await getFilteredBooks(filter, slug, page, size);
            }
            if (page === 0) {
                books.splice(0, books.length);
            }
            pageBooks.data.content.forEach((book: IBookThumbnail) => {books.push(book)});
            setTotalPages(pageBooks.data.totalPages)
            setTotalNumber(pageBooks.data.totalElements);

        } catch (e) {
                console.error(e)
        } finally {
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        getData(page, size);
    },[])


    return (
      <>

          <div className="App">
              <div id="wave" className="parent align-left top-section div-with-background">
                  <div className="small-container ">
                      <br/>
                      {isChristmasCampaign ?
                          <div>
                              <h2>Descoperă cele mai frumoase și captivante cărți de Crăciun și de iarnă!</h2><br/>
                              <h5>În această perioadă magică, îți oferim o selecție atent aleasă de povești care să aducă farmec, căldură și bucurie sărbătorilor tale!
                              </h5>
                          </div>
                          : <div>
                              <h2>E o zi bună pentru a descoperi o carte grozavă!</h2><br/>
                              <h5>Și cu platforma noastră, nu a fost niciodată mai ușor. Avem
                                  câteva <span className="low-highlight-purple">sute de cărți</span> care așteaptă să
                                  fie
                                  descoperite - poți să vezi detaliile,
                                  temele principale ale fiecărei cărți și să descoperi cărți pornind de la autor /
                                  editură / gen / tema dorită.
                              </h5>
                          </div>
                      }
                  </div>
              </div>
              <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
              <div className="App-body fauna-one-regular">
                  {/*<div className="fauna-one-regular">*/}
                  {/*    <Box id="sized-container">*/}

                  <Grid container spacing={2}>
                      {books
                          .filter((book) => book.id !== null)
                                  .map((book, index) => {
                                      const isLastRow = index >= books.length - (books.length % itemsPerRow || itemsPerRow);
                                      return (
                                          <Grid item xs={6} sm={6} md={3} lg={3} key={book.id}>
                                                  <Box>
                                                      <div className={isLastRow && !isChristmasCampaign ? "image_wrapper more-to-load-mask": "image_wrapper"}
                                                      ref={isLastRow && !isChristmasCampaign ? lastElementRef : null}>
                                                          <Link to={`/books/details/` + book.slug}>
                                                              {book.photoUrl ? (
                                                                  <Box key={book.photoUrl} sx={{
                                                                      marginBottom: 2,
                                                                      filter: 'drop-shadow(rgba(205, 180, 219, 0.5) 20px 30px 10px)'
                                                                  }}>
                                                                      <img className='image_wrapper bookInPlan' src={book.photoUrl}
                                                                           alt={book.name} style={{
                                                                          maxHeight: '190px',
                                                                          maxWidth: '190px',
                                                                          objectFit: 'cover'
                                                                      }}/>
                                                                  </Box>

                                                              ) : (
                                                                  <Typography variant="body2">No photos available</Typography>
                                                              )}
                                                          </Link>
                                                          <div className="fauna-one-regular">
                                                            <Typography variant="h6" gutterBottom>{book.name}</Typography>
                                                          </div>
                                                          <div className="overlay overlay_2">
                                                              <h5><strong>Vezi detalii</strong></h5>
                                                          </div>
                                                      </div>
                                                  </Box>
                                              </Grid>
                                      )}
                                  )}
                          </Grid>
                      {/*</Box>*/}
                  {/*</div>*/}
              </div>
              {isChristmasCampaign ?
                      <Button className="button-contact">
                          <Link to={"/christmas-packs"} style={{color: "white"}}>
                              <Park sx={{color: "var(--button-hover-background)"}}/>
                              Achiziționează acum un pachet
                              <Park sx={{color: "var(--button-hover-background)"}}/>
                          </Link>
                      </Button>
                  :
                  <RegisterSection/>
              }
          </div>

      </>
    );
}

export default Books;
