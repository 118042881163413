import * as React from 'react';
import {Button} from 'react-bootstrap'
import {IconButton} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {applyHere} from "../../services/userService";

export default function AppFooter() {
    return (
        <footer className="App-footer parent">
            <div className="column-33"
                 style={{alignItems: 'center', justifyContent: "center", flexDirection: "row"}}>
                <IconButton aria-label="fingerprint" sx={{"&:hover": {color: "#6C00A9"}}}
                            onClick={event => window.open('https://www.facebook.com/raiseareader.kids')}>
                    <FacebookIcon  sx={{color: "var(--cefacem-dark-color)"}}/>
                </IconButton>
                <IconButton aria-label="fingerprint" sx={{"&:hover": {color: "#6C00A9"}}}
                            onClick={event => window.open('https://www.instagram.com/raiseareader.kids')}>
                    <InstagramIcon  sx={{color: "var(--cefacem-dark-color)"}}/>
                </IconButton>
            </div>
            <div className="column-33" style={{alignItems: 'center'}}>
                <Typography>Copyright © 2024 RaiseAReader All rights reserved.</Typography>
            </div>
            <div className="column-33" style={{alignItems: 'self-end', flexDirection: "row", flexWrap:"wrap"}}>
                <Link to={`/contactUs`} onClick={applyHere}>
                    <Button className="button-just-text2">
                        <Typography>Contact</Typography>
                    </Button>
                </Link>
                <Link to={`/terms`}>
                    <Button className="button-just-text2">
                        <Typography>Termeni și condiții</Typography>
                    </Button>
                </Link>
                <Link to={`/privacy`}>
                    <Button className="button-just-text2">
                        <Typography>Confidențialitate</Typography>
                    </Button>
                </Link>
                <Button className="button-just-text2" onClick={() => window.open("https://anpc.ro/", "_blank")}>
                    <Typography>ANPC</Typography>
                </Button>
            </div>
        </footer>
    );
}
