import React, {useState} from 'react';
import '../App.css';
import {Box, CardContent, CardMedia, Grid, Typography} from '@mui/material';
import {Book, SimpleQuestion} from "../services/model/Books";
import {Link} from "react-router-dom";
import {ReadMore} from "../core/components/ReadMore";
import {StarOutline} from "@mui/icons-material";
type Props = {
    book: Book
}
const BookDetailsElement = (props : Props) => {

    const [book, setBook] = useState(props.book);


    function hasQuestions(questionsList: {
        key: string;
        index: number;
        description: string;
        sections: { key: string; index: number; questions: SimpleQuestion[] }[]
    }[]) {
        return questionsList !== undefined && questionsList.length > 0;
    }

    function hasActivities(groups: {
        key: string;
        index: number;
        activities: { name: string; abilities: string; materials: string; instructions: string }[]
    }[]) {
        return groups !== undefined && groups.length > 0;

    }
    return (
        <div style={{width: "inherit"}}>
            <h2></h2>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    {book.photos && book.photos[0] && (
                        <CardMedia
                            component="img"
                            image={book.photos[0].url}
                            alt={book.name}
                            sx={{maxHeight: 350, objectFit: 'contain'}}
                            style={{
                                padding: '20px',
                                filter: 'drop-shadow(-50px 0px 10px rgba(205, 180, 219, 0.5))'
                            }}

                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardContent>
                        <Typography variant="h1" className="book-name">{book.name}</Typography>
                        <p>
                            PRP: <strong>{book.catalogPrice}</strong> <span>Lei</span>
                        </p>
                        {book.authors ?
                            <>
                                <Typography variant="body2"><strong>Scrisă de: </strong>
                                    {book.authors.map((author, index) =>

                                        <Link
                                            to={`/books/author/` + author.slug}> {author.name}{index === book.authors.length - 1 ? '' : ','}</Link>
                                    )}
                                </Typography>
                            </>
                            :
                            <></>
                        }
                        {book.illustrators ?
                            <>
                                <Typography variant="body2"><strong>Ilustrată de:</strong>
                                    {book.illustrators.map((illustrator, index) =>
                                        <Link
                                            to={`/books/illustrator/` + illustrator.slug}> {illustrator.name} {index === book.illustrators.length - 1 ? '' : ','}</Link>
                                    )}
                                </Typography>
                            </>
                            :
                            <></>
                        }
                        <Typography variant="body2">
                        </Typography>
                        {book.bookCollection && book.bookCollection.name ?
                            <>
                                <Typography variant="body2"><strong>Parte din seria:</strong> <Link
                                    to={`/books/collection/` + book.bookCollection?.slug}> {book.bookCollection?.name}</Link></Typography>
                            </>
                            :
                            <></>
                        }

                        <div className="info-banner-container">
                            {book.ageCategories.map((ageCategory, index) =>
                                <div className="info-banner-column">

                                    <Link to={`/books/age-category/` + ageCategory?.slug}>
                                        <div
                                            className="info-banner-value-colored-section">{ageCategory?.name}</div>
                                    </Link>
                                    <div className="info-banner-label">Vârstă recomandată</div>
                                </div>
                            )}

                            <div className="info-banner-column">
                                <div
                                    className="info-banner-value-colored-section">{book.numberOfPages}</div>
                                <div className="info-banner-label">Număr de pagini
                                </div>
                            </div>
                            <div className="info-banner-column">
                                <Link to={`/books/publisher/` + book.publisher?.slug}>
                                    <div
                                        className="info-banner-value-colored-section">{book.publisher?.name}</div>
                                </Link>
                                <div className="info-banner-label">Editură
                                </div>
                            </div>
                        </div>

                        <Typography variant="body1" color="textSecondary">
                            <ReadMore id="book-desc" text={book.description} amountOfWords={45}/>
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>

            <div className="info-topic" style={{width: "inherit"}}>
                <div style={{padding: "20px"}}>
                    <Typography variant="h5" gutterBottom>
                        Ce fel de carte este <span className="italic">{book.name}</span>
                    </Typography>

                    <Typography variant="h6" gutterBottom className="topic">
                        Subiecte
                    </Typography>
                    <div className="book-topic-container" style={{width: "inherit"}}>
                        {book.categories.map((category, index) => (
                            <Link to={`/books/topic/` + category.slug}>
                                <div className="info-banner-column" key={category.id}>
                                    <div className="info-topic-section-white">{category.name}</div>
                                </div>
                            </Link>
                        ))}
                    </div>


                    <Typography variant="h6" gutterBottom className="topic">
                        Genuri
                    </Typography>
                    <div className="book-topic-container">
                        {book.genres.map((genre, index) => (
                            <Link to={`/books/genre/` + genre.slug}>
                                <div className="info-banner-column">
                                    <div className="info-topic-section-white">{genre.name}</div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>


            </div>


            {/*/!* Questions Section *!/*/}

            {/*{book.questionsSection.bookDescription.questions.length > 0 ?*/}
            {/*    <Typography variant="h6" gutterBottom style={{padding: "0px 15px"}}>*/}
            {/*        {book.questionsSection.bookDescription.questions[0].question}*/}
            {/*    </Typography>*/}
            {/*    :*/}
            {/*    <></>*/}
            {/*}*/}

            {hasQuestions(book.questionsSection.questionsList) &&
                <Grid item xs={12} style={{paddingTop: '2rem'}}>
                    <Typography variant="h6" gutterBottom
                                className="info-banner-value-colored-section">
                        <strong>Citire interactivă</strong>
                    </Typography>
                    {book.questionsSection.intro.questions.length > 0 ?
                        <p><Typography gutterBottom
                                       style={{padding: "10px"}}>
                            {book.questionsSection.intro.questions[0].question}
                        </Typography></p>
                        :
                        <></>
                    }
                    {book.questionsSection.questionsList.map((questionGroup, index) => (
                        <Box key={index} className="info-banner-value-activity">
                            <div >
                                <Typography variant="h6" gutterBottom>
                                    <strong>{questionGroup.description}</strong>
                                </Typography>
                            </div>
                            {questionGroup.sections.map((section, secIndex) => (
                                <Box key={secIndex} style={{padding: "0px 0px 20px 10px"}}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <strong>{secIndex + 1}. {section.key}</strong>
                                    </Typography>

                                    {section.questions.map((question, qIndex) => (
                                        <span style={{display: 'flex', marginLeft: '-6px'}}>
                                                            <StarOutline fontSize="small" sx={{color: "var(--cefacem-color)"}}/>
                                                            <Typography style={{paddingLeft: "3px"}}
                                                                variant="body1">{question.question}</Typography>
                                                        </span>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    ))}

                    {book.questionsSection.outro.questions.length > 0 ?
                        <p><Typography gutterBottom
                                       style={{padding: "10px"}}>
                            {book.questionsSection.outro.questions[0].question}
                        </Typography></p>
                        :
                        <></>
                    }

                    {book.questionsSection.generalAdvice.questions.length > 0 ?
                        <div>
                            <Typography variant="subtitle1" gutterBottom>
                                <strong>Sfaturi generale</strong>
                            </Typography>

                            {book.questionsSection.generalAdvice.questions.map((advice, index) => (
                                <Typography variant="subtitle1" gutterBottom>
                                    {index + 1}. {advice.question}
                                </Typography>
                            ))}
                        </div>
                        :
                        <></>
                    }


                </Grid>
            }

            {/*<YouTube videoId="kcmVoP28x5U"*/}
            {/*         opts={{*/}
            {/*             playerVars: {*/}
            {/*                 autoplay: 0,*/}
            {/*                 controls: 1,*/}
            {/*                 showinfo: 0,*/}
            {/*                 rel: 0,*/}
            {/*                 disablekb: 0,*/}
            {/*                 mute: 0,*/}
            {/*                 loop: 0,*/}
            {/*                 mode: 'transparent',*/}
            {/*             },*/}
            {/*         }}/>*/}

            {/* Activities Section */}
            {hasActivities(book.activitiesSection.groups) &&
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom
                                className="info-banner-value-colored-section">
                        <strong> Activități</strong>
                    </Typography>

                    <p><Typography gutterBottom
                                   style={{padding: "10px"}}>
                        {book.activitiesSection.intro}
                    </Typography></p>
                    {book.activitiesSection.groups.map((activityGroup, index) => (
                        <div key={index} style={{padding: "10px 20px 0px 10px"}} className="info-banner-value-activity">
                            <div style={{padding: "0px 0px 20px 10px"}}>
                                {/*<CardContent style={{border: '0px 0px 0px 0px'}}>*/}
                                <div
                                >
                                    <Typography variant="h6" gutterBottom>
                                        <strong>{activityGroup.key}</strong>
                                    </Typography>
                                </div>

                                {activityGroup.activities.map((activity, idx) => (
                                    <div style={{paddingBottom: "1rem"}}>
                                        <Typography variant="h6" gutterBottom>
                                            {activityGroup.activities.length > 1 ?
                                                <strong>{idx + 1}. {activity.name}</strong>
                                                :
                                                <strong>{activity.name}:</strong>
                                            }
                                        </Typography>
                                        {activity.materials != null ?
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Materiale:</strong> {activity.materials}
                                            </Typography>
                                            :
                                            <></>
                                        }

                                        {activity.instructions != null ?
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Instruncțiuni:</strong> {activity.instructions}
                                            </Typography>
                                            :
                                            <></>
                                        }

                                        {activity.abilities != null ?
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Abilități:</strong> {activity.abilities}
                                            </Typography>
                                            :
                                            <></>
                                        }

                                    </div>
                                ))}

                                {/*</CardContent>*/}
                            </div>
                        </div>
                    ))}
                    <Typography variant="subtitle1" gutterBottom
                                style={{padding: "10px"}}>
                        {book.activitiesSection.outro}
                    </Typography>
                </Grid>
            }

            {/*/!* Coloring Page Section *!/*/}
            {/*<Grid item xs={12} style={{padding: '20px'}}>*/}
            {/*    <Typography variant="h5" gutterBottom*/}
            {/*                className="info-banner-value-colored-section">*/}
            {/*        Pagina de colorat*/}
            {/*    </Typography>*/}
            {/*    <Typography variant="subtitle1" gutterBottom*/}
            {/*                style={{padding: "0px 15px"}}>*/}
            {/*        {book.coloringPage.key}*/}
            {/*    </Typography>*/}
            {/*    <Button color="primary" href={book.coloringPage.link}*/}
            {/*            target="_blank" style={{padding: "0px 15px"}}>*/}
            {/*        Descarcă pagina de colorat*/}
            {/*    </Button>*/}
            {/*</Grid>*/}


            {/*</Card>*/}
            {/*</Grid>*/}
            {/*</Grid>*/}
        </div>
    );
}

export default BookDetailsElement;